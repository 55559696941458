@media screen and (max-width: 680px) {
    section.ant-layout {
        display: inline-block !important;
        width: 100%;
    }
}
@media screen and (max-width: 1100px) {
    .modal-cus{
        width: 80%;
    }
    .homepage {
        .cabinets-wrap {
            .card-body {
                display: block;
            }
            .label-chart {
                margin-left: 0 !important;
                margin-top: 20px;
            }
        }
    }
    .homepage {
        .cabinet,
        .map-data {
            flex: 0 0 100%;
            max-width: 100%;
            margin-bottom: 30px;
        }
        .cabinets-wrap .chartjs-render-monitor {
            width: 60% !important;
            height: 60% !important;
            margin: 0 auto;
        }
    }
    .grid-row {
        grid-template-columns: auto;
        grid-template-rows: 0.8fr 0.7fr;
    }
    .company-brand h4 {
        font-size: 20px;
    }
}
@media screen and (min-width: 992px) and (max-width: 1260px) {
    .cus-width {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .flex-wrap {
        flex-wrap: wrap !important;
    }

    .mb20 {
        margin-bottom: 10px;
    }
    .ant-row {
        width: 100%;
    }
}

@media screen and (max-width: 992px) {
    #login-page .logo-top {
        display: inline-block;
    }
    main.ant-layout-content > div {
        padding-left: 0;
        padding-top: 0;
    }

    #login-page .btn-login {
        border: 0;
    }
    #login-page .login-content {
        display: grid;
        grid-template-columns: 0 100% !important;
    }
    #login-page .wrapper {
        background: transparent;
        background-color: transparent;
    }
    .login-form {
        background-image: url(/resources/dtms_img/404.png);
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        border-radius: 12px;
    }
    #login-page .logo-top img {
        float: none;
        margin-right: 0;
        margin-top: -3px;
        // width: 40px;
        margin-bottom: 30px;
    }
    #login-page h4 {
        color: #fff;
    }
    #login-page .logo-top .txt-company {
        margin-top: 10px;
        h2 {
            color: #fff;
            width: 80%;
            text-align: center;
            margin: 0 auto;
        }
    }
    // .btn-filter {
    //     margin-top: 10px !important;
    // }
    .grid-row {
        grid-template-columns: 30% 1fr;
        grid-template-rows: auto !important;
    }
    .grid {
        grid-template-columns: 20% 50% 8%;
    }
    .location {
        margin-top: 30px;
    }
    .ant-layout-header {
        display: grid;
        grid-template-rows: 1fr 0.8fr;
        height: auto !important;
        margin-bottom: 10px;
    }
    .topbar-right {
        i {
            bottom: 0;
        }

        .ant-dropdown-trigger {
            .fe-bell {
                &:after {
                    display: none;
                }
            }
        }
    }
    .form-header .ant-btn {
        position: relative;
    }

    .form-header .ant-btn {
        margin-left: 0;
    }
    #login-page div.login-bg {
        // position: absolute;
        height: 100vh;
    }
    .content-right {
        text-align: center;
        .form-content {
            margin: 0 auto;
        }
    }
    .company-name {
        width: 85%;
    }
    .ant-layout-content {
        padding: 1rem;
        padding-top: 0;
    }
    .form-header-left .ant-select {
        min-width: 100% !important;
    }
    .company-brand img {
        margin-right: 8px;
    }
    .txt-header {
        margin: 0;
    }
    .ant-layout-header .top-right {
        margin-right: 0;
    }
    .top-right {
        margin-top: 22px;
    }
    .form-header.form-header-inline {
        .form-header-left {
            // display: block;
            .display-flex {
                margin-right: 20px;
                .ant-input.txt400 {
                    width: 100%;
                    min-width: 100%;
                    margin-right: 25px;
                }
            }
        }
    }
    .table-responsive {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}
@media screen and (max-width: 768px) {
    div.form-header button.ant-btn {
        margin-top: 15px;
        // margin-right: 10px;
        float: right;
    }
    .grid-view {
        grid-template-columns: 0.8fr 1.2fr;
    }
}
@media screen and (max-width: 600px) {
    #login-page .logo-top .txt-company h2 {
        width: 100%;
    }
    .form-header-left {
        padding: 15px;
    }
    .ant-form-item .ant-form-item-label {
        padding: 0 !important;
    }
    .setting-page .setting-item {
        padding: 0 !important;
    }
    .top-right {
        margin-top: 32px;
    }
    .grid-auto {
        grid-template-columns: auto;
    }
    .ant-dropdown-trigger::after {
        display: none;
    }
    .topbar-header > div {
        &:after {
            display: none !important;
        }
        margin-right: 0;
    }
    .ico-user:after {
        display: none !important;
    }
    main.ant-layout-content > div {
        padding-left: 0 !important;
    }
    #login-page {
        .ant-form-item {
            margin-bottom: 15px !important;
        }
        .content-right .form-content {
            width: 90%;
        }
        .logo-top .txt-company h2 {
            font-size: 18px;
        }
        .btn-login {
            height: 40px !important;
        }
        .logo-top img {
            margin-bottom: 15px;
        }
        .logo-top .txt-company p {
            font-size: 18px;
        }
        .logo-top {
            margin-bottom: 8%;
        }
        h4 {
            font-size: 18px;
            margin-bottom: 26px;
            display: none;
        }
        .ant-input {
            height: 38px !important;
        }
    }

    .ant-layout-content {
        display: inline-block;
        width: 100%;
    }
    .topbar-right {
        padding-bottom: 15px;
    }
    .title-header {
        margin-top: 0;
    }

    .form-header.form-header-inline .ant-btn {
        margin-left: 5px;
        // margin-top: 5px;
    }
    .ant-table-cell .mr20 {
        margin-right: 0;
    }
    main .cui__utils__content {
        padding: 1rem;
    }

    .form-title {
        font-size: 18px;
    }
    div.ant-card .ant-card-body {
        padding: 15px 10px;
    }
}
@media screen and (max-width: 540px) {
    .grid-view {
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    }
    .padding5 {
        padding-left: 15px;
        padding-right: 15px;
    }
    header.ant-layout-header > div {
        padding-left: 0 !important;
    }
}
@media screen and (max-width: 480px) {
    .title-header .btn {
        width: 100%;
    }
    .title-header .btn {
        margin-left: 0;
    }
    div.form-header button.ant-btn {
        // margin-top: 15px !important;
        padding: 4px 5px;
    }
    .company-brand h4 {
        font-size: 17px;
    }
    .grid-row {
        grid-template-rows: 0.8fr 0.7fr;
        grid-template-columns: auto;
    }
    .form-header-left {
        display: block !important;
        margin-right: 0;
        padding: 14px;
    }
    .form-header-left label {
        font-size: 13px;
        margin-bottom: 10px;
    }
    div.company-brand h4 {
        font-size: 16px;
    }
    .company-brand img {
        width: 65px;
    }
    .txt-header h1 {
        font-size: 18px;
    }
    .txt-header h2 {
        font-size: 18px;
    }
    .company-brand img {
        margin-right: 12px;
    }
    .topbar-right {
        padding-top: 0;
    }
}
@media screen and (max-width: 400px) {
    .title-header .btn {
        width: 100%;
    }
    .search-box {
        margin-right: 8px;
    }
    .top-right {
        padding-left: 5px;
        padding-right: 5px;
    }
    .ant-input-search-icon {
        padding: 0;
    }
    .title-header .btn {
        margin-left: 0;
    }
}
