.sidebar-menu {
    width: 55%;
}
div.left-menu {
    background-color: #c3d9f1 !important;
    height: 100vh !important;
    position: fixed;
    height: 100%;
    width: inherit;
    max-width: inherit;
    background-color: #e6eef7;
}
