/////CUSTOMIZE
.company-brand {
  h4 {
    font-size: 20px;
  }
  display: flex;
  align-items: center;
  img {
    margin-right: 20px;
  }
  h3 {
    color: #3951a2;
  }
  h5 {
    color: #ed571f;
    font-size: 18px;
    margin-bottom: 0px;
  }
  img {
    // width: 75px;
    height: 60px;
  }
}
.space-2 {
  margin-left: 2px;
  margin-right: 2px;
}
