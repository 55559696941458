$main-blue: #3951a2;
$main-blue-light: #4b7cf3;
$main-orange: #ed571f;
$blue-light: #e6eef7;
$text-blue: #3951a2;
$text-red: #ff0000;
$text-dark-blue: #414a6d;
$text-orange: #ed571f;
$text-white: #ffff;
$row-table-bg: #c3d9f1;
* {
  box-sizing: border-box;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

html {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
body {
  font-family: 'Play', sans-serif;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  height: 100vh;
  width: 100%;
  padding: 0;
  margin: 0;
  color: #414a6d;
  background: #f2f4f8;
  overflow-y: auto !important;
}
input {
  height: 38px !important;
  border-radius: 6px !important;
}

ul {
  margin: 0;
  padding: 0;
}
ul li {
  list-style-type: none;
}
h2,
h3,
h4 {
  color: $main-blue;
}
h5 {
  color: #707070;
}
p {
  margin-bottom: 10px;
}
label {
  margin-bottom: 0;
}
.display-flex {
  display: flex;
  align-items: center;
}
.display-grid {
  display: grid !important;
  grid-template-columns: 30% 1fr;
}
.grid-view {
  display: grid;
  grid-template-columns: 0.8fr 1.2fr;
  align-items: center;
}
.grid {
  display: grid;
  grid-template-columns: 30% 50% 8%;
  grid-column-gap: 10px;
  align-items: center;
}
//   grid-row-gap: 20px;
// }
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}
.display-inline {
  display: inline;
}
.txt400 {
  width: 400px;
  min-width: 400px;
}
.txt-center {
  text-align: center;
}
.txt-header {
  margin: 30px 0;
}
.txt-header h1 {
  color: #3951a2;
  font-size: 2rem;
}
.txt-header h2 {
  color: $text-orange;
  font-size: 1.8rem;
}
.txt-blue {
  color: #3951a2;
}
.txt-dark-blue {
  color: #414a6d;
  margin-bottom: 36px;
}
.txt-orange {
  color: #ed571f;
}
.txt-green {
  color: #41b883;
}
.text-lower {
  text-transform: capitalize;
}
.ico16 {
  font-size: 16px;
  margin-right: 8px;
}
.ico25 {
  font-size: 18px;
  margin-right: 8px;
}
.ico30 {
  font-size: 26px;
  margin-right: 8px;
  color: #3951a2;
}
.ico-dot {
  font-size: 10px;
  color: #3951a2;
  margin-right: 10px;
}
.ico-white {
  font-size: 25px;
  color: #fff;
  margin-right: 10px;
}
.ico-orange {
  color: $main-orange;
  font-size: 22px;
}

.ico18 {
  font-size: 18px;
  font-weight: bold;
}
.ico-blue {
  color: $main-blue;
}
.ico-red {
  color: $main-orange;
}
.ico-white30 {
  font-size: 30px;
  color: #fff;
  margin-right: 10px;
}
.ico-white25 {
  font-size: 25px;
  color: #fff;
  margin-right: 10px;
}
.i_search.small {
  margin-right: 8px;
  font-size: 15px;
}
.fl-right {
  float: right;
  text-align: right;
  width: 100%;
  // margin-bottom: -5px;
}
.coppy-right {
  position: absolute;
  bottom: 60px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  padding: 0 6px;
  font-size: 14px;
}
.nopadding {
  padding: 0;
}
.padding5 {
  padding-left: 5px;
  padding-right: 5px;
}
.padding10 {
  padding-top: 10px !important;
}
.padding15 {
  padding: 15px !important;
}
.padding25 {
  padding: 25px !important;
}
.pr10 {
  padding-right: 10px;
}
.margin5 {
  margin-left: -5px !important;
  margin-right: -5px !important;
}
.mr20 {
  margin-right: 20px;
}
.mbt5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.ml10 {
  margin-left: 10px !important;
}
.mtb10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.mt10 {
  margin-top: -10px !important;
}
.mr-top30 {
  margin-top: 30px;
}
.mr-top15 {
  margin-top: 15px;
}
.mr-top40 {
  margin-top: 40px;
}
.mt70 {
  margin-top: 70px !important;
}
.mb20 {
  margin-bottom: 20px;
}
.center {
  text-align: center;
  margin: 0 auto;
}
input,
input:active,
input:focus {
  outline: 0;
  outline-style: none;
  outline-width: 0;
}
textarea {
  resize: none;
}

.input-cus {
  border: 1px solid #95afcc;
  height: 38px !important;
  padding: 10px;
  margin-right: 10px !important;
  margin-bottom: 8px;
  width: 80%;
  border-radius: 4px !important;
}
.ant-input:focus {
  box-shadow: none !important;
}
// .ant-select-selector .ant-select-selection-search {
//   position: relative !important;
// }
.lbl-block {
  float: left;
}
.cabinets-wrap {
  .card-body {
    align-items: center;
    min-height: 320px;
    position: relative;
    display: grid;
    grid-template-rows: 1fr 0.6fr;
  }
  .chartjs-render-monitor {
    position: relative;
  }
  .count-label {
    top: 55% !important;
  }
}

.title-row {
  margin-top: 10px;
}
span.ant-avatar-lg.ant-avatar-icon {
  font-size: 22px !important;
}
.block-left {
  width: 100% !important;
}
//////////status////////////

.status-confirm {
  background: #dfb02c;
  color: #fff;
  padding: 10px 20px;
  border-radius: 30px;
}
.card-header.main-title {
  background: $main-blue;
  padding: 5px 15px;
  strong {
    color: #fff;
    font-size: 16px;
  }
}
.cabinet-list-status {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
  width: 100%;
  .lbl-alert,
  .lbl-warn {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  span {
    border-radius: 6px;
    padding: 8px 15px;
    color: red;
    margin-right: 10px;

    &.lbl-light-org {
      background: #f3dcc2;
    }
    &.lbl-yellow {
      background: #f5cd2b;
    }
    &.lbl-dark-org {
      background: #ed571f;
      color: #fff;
    }
    &.lbl-offline {
      background-color: #404040;
      color: white;
    }
    &.lbl-alert {
      background-color: #ee3f23;
      color: white;
    }
    &.lbl-warn {
      background-color: #faad14;
      color: white;
    }
  }
}

//////////////TOPBAR/////////////////
li.ant-dropdown-menu-item {
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #aeaee0;
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #c3d9f1;
    -webkit-box-shadow: inset 0 0 6px #aeaee0;
  }
  ::-webkit-scrollbar-thumb:window-inactive {
    background: #c3d9f1;
  }
}

div.warning-item {
  font-size: 14px;
  border-radius: 6px;
  display: block;
  padding-left: 15px;
  padding-right: 15px;
  p {
    div {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
  }
}
.ds {
  display: flex;
}
.ant-list-item time {
  padding-left: 25px;
}
.warning-list {
  &.warning-page {
    max-height: 100%;
    overflow: hidden;
  }
  height: 100%;
  max-height: 300px;
  overflow-y: auto;
  .ant-list-item {
    background: #e6eef7;
    margin-bottom: 10px;
    border-radius: 6px;
    padding: 10px 0;
    p {
      color: #3951a2;
    }
    &:hover,
    &:focus,
    &:active {
      background: #d4dbe4;
    }
  }
}
.ant-dropdown-menu-item:hover {
  background-color: transparent !important;
}
ul li.ant-dropdown-menu-item-divider {
  margin: 0 !important;
}
.ant-layout-header .icon {
  bottom: 0;
}
.ant-layout .handler {
  right: 0;
  left: auto;
  z-index: 999 !important;
  background: #3951a2;
  top: 0;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.topbar-tab {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px !important;
  padding-bottom: 20px;
  .ant-tabs-ink-bar,
  .ant-tabs-bar::before {
    display: none !important;
  }
  small {
    padding-left: 25px;
    font-weight: bold;
    color: #a1a1c2;
  }
  .ant-tabs-bar {
    background: #549def;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    .ant-tabs-nav .ant-tabs-tab-active {
      color: #fff;
      font-family: 'play';
      font-size: 16px;
    }
    .style_tabs__TGymF .ant-tabs-bar::before {
      display: none;
    }
  }
  li {
    p {
      margin-bottom: 0;
    }
    b {
      font-size: 16px;
    }
    i {
      color: #ed9517;
    }
    background: #e6eef7;
    border-radius: 4px;
    padding: 10px;
    &.warning-unread {
      background: #f2f2e0;
    }
  }
}
// .ant-dropdown {
//   // max-width: 300px;
//   position: relative !important;
// }

.ant-dropdown ul.ant-dropdown-menu {
  min-width: 220px;
  padding: 0;
  border-radius: 6px;
}
.ant-list-item-meta {
  margin-bottom: 0 !important;
}
ul .ant-dropdown-menu-item {
  strong {
    font-size: 15px;
  }
  .i_warning_18 {
    color: #ed9517;
  }
  color: #414a6d;
  padding: 10px 15px !important;

  i {
    margin-right: 10px;
    font-size: 18px;
    color: #595c97;
  }
}
.ant-dropdown-menu-item-divider {
  margin: 0;
}
.ant-dropdown-menu-item.dropdown-title {
  background: #549def !important;
  color: #fff;
  padding: 12px 20px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  &:hover {
    background: #549def !important;
  }
}
.topbar-right {
  display: flex;
  padding-right: 0;
  .ant-avatar-icon {
    background: #3951a2 !important;
    border-radius: 4px;
  }
  i {
    background: #3951a2 !important;
    height: 40px;
    width: 40px;
    display: inline-block;
    line-height: 40px;
    text-align: center;
    margin-right: 10px;
    border-radius: 4px;
    color: #fff;
    font-size: 20px;
  }
}
.label-count {
  background: #ed571f;
  color: #fff;
  position: absolute;
  right: -2px;
  top: -14px;
  z-index: 9;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  font-size: 10px;
  line-height: 28px;
  text-align: center;
}
div.ico-dropdown::after {
  opacity: 0.5;
  color: #595c97;
  position: absolute;
  top: 45%;
  right: -5px;
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  transition: all 0.2s ease-in-out;
}
.ico-user:after {
  opacity: 0.5;
  color: #595c97;
  position: absolute;
  top: 45%;
  right: -15px;
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.2em solid;
  border-right: 0.2em solid transparent;
  border-bottom: 0;
  border-left: 0.2em solid transparent;
  transition: all 0.2s ease-in-out;
}
.ico-user {
  color: #fff;
  font-size: 22px;
}
.sidebar_menu {
  margin: 0 auto;
  height: 100%;
}
.scrollbar-container {
  //overflow-y: scroll !important;
  padding-top: 10px;
  position: absolute;
  width: 100%;
  padding-bottom: 150px;
}
.topbar-right {
  width: 100%;
  line-height: 0;
  padding-top: 2%;
}
.search-box {
  width: 100%;
  margin-right: 20px;
  position: relative;
  .ant-input-clear-icon svg {
    font-size: 14px !important;
  }
}
.search-box i {
  position: absolute;
  top: 3px;
  font-size: 20px;
}
// .search-box .ant-input {
//   height: 42px !important;
// }
.ant-input-suffix span.ant-input-search-icon {
  padding: 8px !important;
  margin-left: 0;
}
.ant-input-suffix span.ant-input-search-icon::before {
  border-left: 0;
}

.ant-form-item-control-input-content span.ant-input-affix-wrapper {
  textarea {
    border: 0 !important;
  }
  padding: 0 11px !important;
  border: 0;
  border-radius: 6px !important;
  border: 1px solid #c3d9f1 !important;
}
.ant-menu {
  background: #c3d9f1 !important;
}
div.menu-mobile {
  position: fixed;
  left: auto;
  z-index: 999;
  right: 0;
  top: 0;
  width: 41px;
  height: 40px;
  cursor: pointer;
  border-top-left-radius: 6px;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #3951a2;
  border-radius: 4px 0px 0px 4px;
}
.ant-menu-item {
  background-color: #549def !important;
  .menu-icon {
    font-size: 1.6rem !important;
    line-height: 35px;
  }
}
li.ant-menu-item-selected {
  background: #3951a2 !important;
}
.ant-menu li.ant-menu-item {
  margin-bottom: 10px !important;
  &:hover {
    background-image: linear-gradient(
      315deg,
      #3951a2 0%,
      #5797f9 74%
    ) !important;
  }
}
.ant-layout div.menu {
  background: $blue-light !important;
}
.sidebar_menu .ant-menu {
  background: #c3d9f1 !important;
  padding: 0 1rem !important;
}
.user-account {
  width: 42px;
  height: 42px;
  display: block;
  text-align: center;
  line-height: 38px;
  background: #3951a2;
  border-radius: 4px;
}

.ant-layout-header {
  position: relative;
}

header.ant-layout-header > div {
  padding: 8px !important;
  min-height: 98px !important;
  line-height: 48px;
  background-image: linear-gradient(315deg, #c8d9f1 0%, #f0f6ff 74%);
}
// .ant-layout-header .top-right {
//   margin-right: -20px;
// }
.ant-dropdown-trigger > i {
  bottom: 0;
}
.ant-layout-sider-children {
  background: #c3d9f1;
}
.ant-input {
  font-family: 'Play', sans-serif !important;
  border: 1px solid #e4e9f0;
}

.form-group {
  margin: 1rem;
  display: flex;
  align-items: center;
}
///////////////TITLE HEADER////////////
.title-header {
  margin-top: 25px;
  .btn {
    margin-left: 2px;
  }
}
////////////////INPUT//////////////////

.form-header-left .form-group {
  margin: 0;
}
//////////BUTTON//////////
.search-box .ant-input-affix-wrapper-lg {
  padding: 0 18px;
  font-size: 16px;
  height: 42px;
  border: 1px solid #c3d9f1;
  svg {
    font-size: 22px;
    font-weight: bold;
  }
}
.btn-search {
  background: transparent;
  border: 0;
  position: absolute;
  right: 0;
}
.btn-filter:hover {
  background: #1f2667 !important;
}
.btn-group-footer {
  margin-top: 70px;
}
div.form-header button.ant-btn {
  background-color: transparent;
  border: 0;
  color: #fff !important;
  background: #549def !important;
  width: 100%;
  max-width: 140px;
  min-width: 100px;
  height: 40px;
  margin-top: 15px;
  border-radius: 4px;
  // float: right;
}
[data-kit-theme='default'] .ant-btn {
  line-height: 0;
}
.btn-footer {
  margin-top: 20px;
}

///////////////BACKGROUND///////////////
.bg-block {
  background: #c3d9f1;
  border-radius: 6px;
  padding: 14px;
  border: 1px solid #afc8e5;
  margin-left: 10px;
  margin-right: 10px;
  min-height: 100%;
  padding-bottom: 0;
  p {
    display: flex;
    margin-bottom: 5px;
    text-transform: capitalize;
  }
  // .ant-form-item {
  //   margin-bottom: 18px !important;
  // }
}
//////////////////ICON///////////////////
.btn-ico-edit {
  color: $text-dark-blue;
  margin-right: 20px;
}
.btn-ico-del {
  color: $main-orange;
}
////////////////TABLE///////////////////
.div.ant-table {
  color: #414a6d;
}
.grid-column {
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: 0.2fr 0.2fr;
  justify-content: center;
}
div.ant-table-content {
  margin-top: 1px;
}
.ant-spin-container div.ant-table.ant-table-bordered div.ant-table-container {
  border: 0;
}
.ant-table-content .ant-table-tbody > tr > td {
  background: $blue-light;
  text-align: center;
  padding: 10px;
  border: 1px solid #fff;
  line-height: 18px;
}
.ant-table-content .ant-table-thead > tr > th {
  background: #337ab7;
  color: #fff;
  border: 1px solid #fff;
  text-align: center;
}

.ant-table-container .ant-table-content .ant-table-tbody tr.ant-table-row {
  &:hover {
    background: #78a3ff;
  }
}
///////////////PAGINATION////////////////
.ant-select-single .ant-select-selector {
  align-items: center;
  background: #f2f4f8;
  border-radius: 4px;
}
.ant-pagination li.ant-pagination-next,
.ant-pagination li.ant-pagination-prev {
  width: 36px;
  height: 36px;
  line-height: 36px;
}
.ant-pagination li.ant-pagination-item,
li.ant-pagination-next {
  width: 36px;
  height: 36px;
  line-height: 36px;
  border: 0;
  border-radius: 4px;
}
.ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 38px;
}
li.ant-pagination-item.ant-pagination-item-active a {
  color: #fff;
  background: $main-blue !important;
}
li.ant-pagination-item a,
.ant-pagination li.ant-pagination-prev a.ant-pagination-item-link,
.ant-pagination li.ant-pagination-next a.ant-pagination-item-link {
  background: $blue-light;
  color: $main-blue;
  border: 0 !important;
  border-radius: 4px;
}
.ant-pagination-item-active a {
  background: $main-blue;
  color: $text-white;
}
////////////////LOGIN PAGE////////////////
#login-page {
  max-width: 1430px;
  margin: 3% auto;
  border-radius: 12px;
  box-shadow: 0 24px 32px 0 rgba(44, 44, 44, 0.1);
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #cfdbf4 inset !important;
  }
  .login-content {
    display: grid;
    grid-template-columns: 40% 60%;
  }
  .wrapper {
    background: #ccd8f3;
    background: linear-gradient(0deg, #ccd8f0 0%, #ccd8f3 0%, white 100%);
    border-radius: 12px;
  }
  .content-right {
    padding: 0 5%;
    .form-content {
      width: 70%;
    }
  }
  .ant-col.ant-form-item-control {
    .ant-input-affix-wrapper {
      background: #cfdbf4 !important;
    }
  }
  .login-bg {
    // background-image: url(Retina-image-800x600-2x.png);
    background-image: url(/resources/dtms_img/bg404@2x.png);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 90vh;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  h4 {
    font-size: 23px;
  }
  .ant-input {
    height: 46px !important;
  }
  .form-group {
    margin-bottom: 1.5rem;
  }
  .logo-top {
    display: flex;
    width: 100%;
    margin-bottom: 8%;
    margin-top: 10%;

    img {
      float: left;
      margin-right: 20px;
      margin-top: 9px;
      // width: 75px;
      height: 75px;
    }
    .txt-company {
      h2 {
        font-size: 28px;
        color: #3951a2;
        margin-bottom: 0px;
      }
      p {
        font-size: 22px;
        color: #ed571f;
      }
    }
  }
  .btn-login {
    background-color: #ed571f;
    color: #fff;
    height: 50px !important;
    font-size: 18px;
    margin-top: 15px;
  }
}
////////////////OVERWRITE/////////
.ant-tabs-nav .ant-tabs-tab {
  line-height: 20px;
}

.slide-fadein-up {
  height: 100%;
  padding-right: 0 !important;
}
main.ant-layout-content > div {
  flex-grow: 1;
  height: calc(100% - 50px);
  padding-right: 0;
  // padding-left: 2rem;
}

.detail-page {
  height: 100%;
  background: #e6eef7;
  border-radius: 6px;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
  }

  .form-title {
    background: #f2f4f8;
    padding-bottom: 10px;
  }
  .ant-card {
    background: transparent !important;
    border: 0;
  }
  .card-content {
    min-height: 100%;
    display: block;
    padding-bottom: 40px;
  }
}
.ant-form-item-label {
  text-align: left;
}
.ant-form-item-label > label::after {
  content: '';
}
.ant-form-item-label > label {
  height: auto;
}
.ant-input,
div.ant-select-selector,
.ant-picker {
  height: 40px;
  width: 100%;
  line-height: 40px !important;
  border-radius: 4px !important;
}

.width100 {
  .ant-form-item-label {
    width: 100%;
    text-align: left;
  }
  .select-cus {
    width: 95% !important;
  }
}

.ant-pagination-disabled .ant-pagination-item-link {
  background: $blue-light;
}

///////////////////FORM///////////////////
.form-header.form-header-inline {
  .form-header-left {
    // padding: 10px 0 10px 20px;
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
    border-radius: 0;
    display: flex;
    label {
      margin-bottom: 0;
    }
  }
  .ant-btn {
    position: relative;
    bottom: 0;
    margin-left: 10px;
    margin-top: 0;
    color: #fff;
    height: 38 !important;
  }
}
.btn.btn-green:hover,
.btn.btn-green:focus,
.btn.btn-green {
  background: #47b883;
}
.btn.btn-blue:hover,
.btn.btn-blue:focus,
.btn.btn-blue.active {
  background: #3951a2 !important;
}
.form-title {
  color: $text-orange;
  font-size: 20px;
  margin-bottom: 15px;
  margin-bottom: 8px;
  margin-top: 20px;
}
.form-header.grid {
  display: grid;
  grid-template-columns: 1fr 150px;
  grid-gap: 2px;
}
input[type='text'] {
  border-radius: 4px !important;
}
.form-header-left {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  margin-top: 2px;
  background: #3951a2;
  margin-top: 2px;
  align-items: center;
  padding: 14px 15px 14px 25px;
  .row {
    align-items: flex-end;
  }
  label {
    color: $text-white;
    margin-right: 20px;
    margin-bottom: 15px;
    font-size: 14px;
  }
  .ant-select {
    display: block;
  }
  .ant-input {
    display: block;
    background-color: transparent;
    margin-top: -5px;
  }
  .ant-input-affix-wrapper {
    height: 40px;
  }
}
span.ant-input-group .ant-input {
  float: left;
  min-width: 250px;
  margin-left: 20px;
  margin-bottom: -10px;
  text-align: inherit;
}
.form-header-left .input-group {
  min-width: 250px;
}
.btn-search span {
  position: absolute;
  top: 3px;
  right: 8px;
}
.form-header-right {
  border-radius: 4px;
  color: $text-white;
  border-radius: 6px;
  // margin-left: auto;
}
.ant-form-item {
  margin-bottom: 10px !important;
}
///////////////CARD/////////////////
.cabinet .card p {
  margin-bottom: 0;
}
div.ant-card-bordered {
  border: 0;
}
div.ant-card {
  color: #414a6d !important;
  border: 0;
  font-size: 16px !important;
  .ant-card-head-title {
    color: $main-blue;
    font-size: 18px;
  }
  .card-text-right {
    color: #3850a2;
  }
  div.ant-card-head {
    background-color: $row-table-bg;
    height: 56px;
    line-height: 20px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  .ant-card-body {
    background: transparent;
    // padding: 30px;
    line-height: 20px;
    li {
      border-bottom: 1px solid #cacee6;
      padding: 10px 0;
      display: flex;
      font-size: 15px;
      .card-text-left {
        width: 40%;
        color: #414a6d;
      }
      &:last-child {
        border: 0;
        margin-top: 15px;
      }
    }
  }
}
/////////////////BUTTON///////////////////
button {
  height: 40px !important;
  border-radius: 6px !important;
}
.btn-center-bottom {
  text-align: center;
  margin-top: 40px;
  justify-content: center;
}
@mixin button-bg($bg) {
  background: $bg;
  &:hover {
    background: darken($bg, 8%);
    transition: all 0.3s ease;
  }
  &:active {
    background: darken($bg, 25%);
  }
}

.btn {
  color: $text-white !important;
  text-decoration: none;
  padding: 5px 20px;
  border-radius: 6px;
  margin: 0 10px;
  margin-right: 0;
  &:hover,
  &:focus {
    opacity: 0.8;
    background: #3951a2 !important;
  }
  i {
    margin-right: 10px;
  }
}
.btn.btn:disabled {
  background: #b9b9c5;
  border: 1px solid #94949e;
}
.btn {
  padding-left: 25px;
  padding-right: 25px;
  line-height: 32px;
  min-width: 120px;
  &.btn-blue {
    background: #337ab7;
  }
  &.btn-cancel {
    background: #f14336;
  }
  &.btn-grey {
    background: #a1a1c2;
  }
  &.btn-save {
    background: #595c97;
  }
  &.btn-update {
    background: #4b7cf3;
  }
  &.btn-orange {
    background: #ed571f !important;
  }
  &.btn-yellow {
    background: #ed9517;
  }
  &.btn-edit {
    background: #4b6584;
  }
  &.btn-confirm {
    background: #3951a2;
  }
  &.btn-setting {
    background: #4b7cf3;
    margin-top: 10px;
  }
  &.btn-statis {
    background: #3951a2;
  }
  &.btn-export {
    background: #0e7967;
  }
  &.btn-delete {
    background: #f14336;
  }
  &.btn-control-off {
    background: #ff0000;
  }
  &.btn-control-on {
    background: #3f932e;
  }
  &.btn-control-cut {
    background: #4c5a86;
  }
}
.btn-blue.on-focus {
  background: #3951a2;
}
/*Other Stylez*/
.wrap {
  margin: 0 auto;
  width: 800px;
  text-align: center;
}
////// input//////
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  opacity: 1;
}
.bg-block {
  .ant-form-item-control-input-content {
    // display: flex;
    align-items: center;
  }
  .ant-form-horizontal .ant-form-item-control {
    flex: auto;
  }
}
.modal-small .ant-form-item-control {
  width: 100% !important;
}
//////DETAIL//////
.detail-form {
  .ant-form-item-label {
    text-align: left;
  }
  // .form-content-left {
  //   padding-right: 30px;
  // }
  &.display-inline {
    .ant-row {
      display: inline;
    }
  }
}
.detail-page {
  margin-top: 20px;
}
.detail-form {
  background: #e6eef7;
  border-radius: 6px;
  .ant-col.ant-form-item-label {
    // width: 30%;
  }
}
.detail-form-title {
  height: 60px;
  background: #c3d9f1;
  color: $text-dark-blue;
}
///////SETTING PAGE///////
.setting-page {
  .ant-card-body {
    padding-top: 100px;
  }

  .setting-item {
    display: grid;
    grid-template-columns: 0.8fr;
    justify-content: center;
    padding: 0 10%;
    input {
      background: #fff !important;
    }
  }
}

/////MODAL/////
.modal-small {
  .ant-modal-header {
    background: #4b7cf3 !important;
    padding: 10px 24px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    .ant-modal-title {
      color: #fff;
      text-align: left;
      .anticon {
        margin-right: 10px;
      }
    }
  }
  .ant-modal-content {
    .ant-modal-body {
      font-size: 17px;
      text-align: center;
      padding-bottom: 10px;
    }
    border-radius: 8px !important;
    background-color: #b8c6db;
    background-image: linear-gradient(181deg, #fff 30%, #c1daf3 87%);
    margin-top: 50%;
  }

  .ant-modal-footer {
    border-top: 0;
    padding-bottom: 20px;
    text-align: center;
    .btn {
      height: 42px !important;
      min-width: 120px !important;
    }
  }
  .ant-modal-close {
    top: -5px;
    & :hover {
      opacity: 0.5;
    }
  }
  span.ant-modal-close-icon {
    color: #fff;
    border: 2px solid #fff;
    width: 23px;
    height: 23px;
    padding: 2px;
    border-radius: 50%;
  }
}

///////////modal confirm
div.ant-modal-confirm {
  div.ant-modal-body {
    padding: 0 !important;
  }
}
.ant-modal-body {
  label {
    float: left;
  }
  div.ant-form-item {
    margin-bottom: 0px !important;
  }
}
/////////////page 404/////////////
.page404 {
  background-image: url(/resources/dtms_img/404.png);
  background-size: 100%;
  background-repeat: no-repeat;
  display: block;
  // object-fit: cover;
  width: 100vw;
  height: 100vh;
  text-align: center;
  padding-top: 15%;
  color: #fff;
  overflow-y: hidden;
  h2 {
    font-size: 100px;
    color: #fff;
  }
  h3 {
    font-size: 30px;
    color: #5e83fd;
  }
  .btn {
    border: 0;
    margin-top: 20px;
    margin-left: 0;
  }
}
.detail-heading {
  color: #ed5c28;
  text-transform: capitalize;
}
// MAP
.location {
  margin-bottom: 15px;
}

.msg-fail {
  color: red;
  font-size: 12px;
  float: left;
  width: 100%;
  text-align: left;
  margin-top: -14px;
}
.device-config-item {
  width: 60% !important;
}
.device-config-value {
  width: 40% !important;
}
.cursor {
  cursor: pointer;
}

label.ant-form-item-required::before {
  display: none !important;
}
.break-word {
  max-width: 100px;
  word-break: break-all;
  td {
    max-width: 300px;
    word-break: break-all;
  }
}
// .form-header-left .ant-input-affix-wrapper {
//   position: relative;
//   background-color: #ffffff;
//   border: 1px solid #e4e9f0;
//   border-radius: 2px;
//   transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
//   width: 100%;
//   height: 32px;
//   padding: 0 11px;
// }
[data-kit-theme="default"] .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
  height: 40px;
}
.ant-select-dropdown.ant-tree-select-dropdown.ant-select-dropdown-placement-bottomLeft{
  .ant-select-tree > div > input{
    display: none!important;
  }
}
.custom-antd-table{
  ul.ant-pagination.ant-table-pagination.ant-table-pagination-right {
    display: none;
  }
}
.custom-pagination{
  margin-top: 20px;
  li {
    min-width: 50px;
  }
}
