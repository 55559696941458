.warning-line{
    .flash-container .flash-alert {
        background-color: rgb(253, 212, 205);
        color: rgb(174, 24, 0);
    }
    .flash-container.sticky .flash-alert, .flash-container.sticky .flash-notice, .flash-container.sticky .flash-success, .flash-container.sticky .flash-warning {
        margin-bottom: 0.75rem;
    }
    .flash-container .flash-alert, .flash-container .flash-notice, .flash-container .flash-success, .flash-container .flash-warning {
        margin-top: 10px;
        padding: 16px 32px 16px 20px;
    }
    .flash-container .flash-text, .flash-container .flash-action {
        display: inline-block;
    }
    
}